.container {
    background-color: #0F1213;
}

.heading1 {
    width: 537px;
    height: 124px;
}

.heading2 {
    width: 414px;
    height: 54px;
}

.textBg {
    background: linear-gradient(135deg, #F1BE68 10%, #DA8A42 138%);
    background-clip: text;
    color: transparent;
}

.platform {
    position: absolute;
    width: 781.74px;
    height: 346.08px;
    margin-top: 370px;
}

.lap {
    position: absolute;
    width: 655.3px;
    height: 377.9px;
    right: 10;
}
.elipse {
    width: 447px;
    height: 427px;
    margin-top: 120px;
    margin-left: 200px;
    background: rgba(43, 153, 255, 0.67);
    filter: blur(250px);
    right: 10;
}
.platformImg{
    filter: contrast(0%) brightness(175%);
    -webkit-filter: contrast(0%) brightness(175%);
    height: 346.08px;
    width: 781.74px;
}

.box5 {
    position: absolute;
    margin-top: 10px;
    margin-left: 70px;
    filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
    transform: rotate(-1deg);
}

.box6 {
    position: absolute;
    margin-top: 350px;
    margin-left: 30px;
    filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
    transform: rotate(-7.93deg);
}

.box7 {
    position: absolute;
    margin-top: 450px;
    margin-left: -10px;
    filter: drop-shadow(30px 34px 44px rgba(0, 0, 0, 0.25));
}


.box9 {
    position: absolute;
    margin-top: 390px;
    margin-left: 400px;
    filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
}

.graphicsContainer {
    background-color: black;
}

.closeButton{
    border-radius: 50%;
    -webkit-filter: invert(1) contrast(10%) brightness(50%);
    filter: invert(1) contrast(10%) brightness(50%);
}
.closeButton:hover{
    -webkit-filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
    filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
}

@media only screen and (max-width: 1400px) {
    .graphicsContainer {
      background-color: red;
    }
}