.touHeading {
    font-size: 60px;
    line-height: 62px;
    font-style: normal;
    font-weight: bold;
    font-family: 'Product Sans';
    color: #282C30;
}

.subHeading {
    font-size: 24px;
    line-height: 27px;
    font-style: normal;
    font-family: 'Product Sans';
    color: #282C30;
}

.textStyle {
    font-size: 16px;
    line-height: 27px;
    font-style: normal;
    font-family: 'Product Sans';
    margin-left: 2px;
    color: #282C30;
}