.buttonClickEffect {
  text-transform: none;
  color: white;
  background: linear-gradient(135deg, #f1be68 0%, #da8a42 100%);
  border-radius: 5px;
}
.closeButton {
  border-radius: 50%;
  -webkit-filter: invert(1) contrast(10%) brightness(50%);
  filter: invert(1) contrast(10%) brightness(50%);
}
.closeButton:hover {
  -webkit-filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
  filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
}
.buttonClickEffect:hover {
  /* text-transform: none; */
  box-shadow: inset 0 0 70px rgba(0, 0, 0, 0.4);
  border: 2px solid #ebaf5d;
  border-radius: 5px;
  box-sizing: border-box;
  /* box-shadow: inset 0em -0.3em 0.4em 0.2em #ca9e03a6; */
}
/* .buttonClickEffect::backdrop {
      text-transform: none;
      border-radius: 50%;
      z-index: 5;
      height: 32px;
      width: 32px;
      background-color: red;
  } */
#reveal-poster {
  background-color: transparent;
}
.gradientStyle {
  height: 400px;
  width: 450px;
  margin-top: 140px;
  margin-left: 50px;
  background: radial-gradient(circle, rgba(240, 226, 232, 1) 0%, rgba(155, 163, 175, 0) 65%);
  /* background: url(../../effect.png); */
  background-blend-mode: lighten;
}
.objectLeft1 {
  position: absolute;
  margin-top: 10px;
  /* margin-left: -150px; */
  left: -0.5%;
  /* opacity: 1; */
  z-index: 200;
}
.objectLeft6 {
  position: absolute;
  margin-top: 569px;
  /* margin-left: -150px; */
  left: -0.5%;
  /* opacity: 1; */
}
.objectLeft2 {
  z-index: 21;
  /* position: absolute; */
  width: 44.06px;
  height: 226.56px;
  margin-top: -240px;
  margin-left: 200px;
  /* left: 10%; */
  /* background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%); */
  opacity: 0.6;
  border-radius: 28.3826px;
  transform: rotate(-180deg);
}
.objectLeft3 {
  position: absolute;
  width: 51px;
  height: 869px;
  /* margin-top: 1px; */
  margin-left: 550px;
  /* background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2A1ECD 100%); */
  border-radius: 20px;
}

.objectLeft4 {
  position: absolute;
  width: 32.71px;
  height: 297px;
  margin-top: 460px;
  margin-left: 50px;
  background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2a1ecd 100%);
  opacity: 0.7;
  border-radius: 20px;
  transform: rotate(90deg);
}

.objectLeft5 {
  position: absolute;
  width: 32.71px;
  height: 157px;
  margin-top: 530px;
  /* margin-left: 1px; */
  background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #d038eb 100%);
  border-radius: 20px;
  opacity: 0.25;
  transform: rotate(-90deg);
}
.objectRight1 {
  position: absolute;
  width: 42.5px;
  height: 144px;
  margin-left: 1150px;
  margin-top: 43px;
  /* background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%); */
  border-radius: 20px;
  opacity: 1;
  transform: rotate(90deg);
  z-index: 22;
}

.objectRight2 {
  position: absolute;
  width: 44.83px;
  height: 322px;
  margin-left: 1100px;
  margin-top: 950px;
  /* background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2A1ECD 100%); */
  border-radius: 26.1951px;
  transform: rotate(90deg);
}

.objectRight3 {
  position: absolute;
  width: 44.83px;
  height: 248.66px;
  margin-left: 1000px;
  margin-top: 945px;
  background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #d038eb 100%);
  border-radius: 26.1951px;
  opacity: 0.3;
  transform: rotate(-90deg);
}

.objectRight4 {
  position: absolute;
  margin-left: 850px;
  margin-top: 94px;
  border-radius: 20px;

  /* transform: rotate(90deg); */
}

.objectRight5 {
  position: absolute;
  margin-left: 850px;
  margin-top: 90px;
  border-radius: 20px;
}

.objectRight6 {
  position: absolute;
  margin-left: -80px;
  margin-top: 720px;
  border-radius: 20px;
}

.objectRight7 {
  width: 44.83px;
  height: 522px;
  border-radius: 26.1951px;
}

.objectRight8 {
  border-radius: 100px;
  margin-top: -110px;
  margin-left: 390px;
  margin-bottom: -60px;
}

.objectRight9 {
  border-radius: 100px;
  /* margin-top: -10px; */
  position: absolute;
  right: 0;
  /* margin-left: 270px;
    margin-bottom: -190px; */
}

.purpleobj {
  position: absolute;
  margin-left: 760px;
  margin-top: -460px;
  filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  transform: rotate(4.93deg);
}

.blackobj {
  position: absolute;
  /* width: 225.62px;
      height: 169.22px; */
  margin-left: 820px;
  margin-top: 50px;
  filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  transform: rotate(5.93deg);
}

.blackobj5 {
  position: absolute;
  /* width: 225.62px;
      height: 169.22px; */
  margin-left: -800px;
  margin-top: 200px;
  filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  transform: rotate(5.93deg);
}
.whiteobj {
  position: absolute;
  margin-left: -650px;
  margin-top: 640px;
  filter: drop-shadow(30px 34px 44px rgba(0, 0, 0, 0.25));
}

.blueBg {
  position: absolute;
  width: 766px;
  height: 78px;
  /* margin-left: 300px; */
  margin-top: 500px;
  /* background: #2A1ECD; */
  filter: blur(130px);
  background: rgba(94, 102, 115, 0.42);
}

.options {
  color: #282c30;
}
/* .options:hover {
  /* position: absolute; */
/* width: 272px;
      height: 308px; */
/* left: 118px;
      top: 1546px; */
/* justify-content: center; */
/* align-items: center; */
/* background: #798392 #1A1C1F; */
/* box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25); */
/* background: #798392;
  box-shadow: 0px 8.13333px 37.17px rgba(0, 0, 0, 0.15); */
/* shadow2 */
/* padding-left: 25px;
      padding-bottom: 20px; */
/* margin-left: -50px; */
/* border-radius: 10px; */
/* border-color: red;
      border-width: 100px; */
/* color: #ffffff; */
/* }  */
.optionsText {
  color: #282c30;
}
/* .optionsText:hover {
  color: #ffffff;
} */

.textBG {
  background: linear-gradient(135deg, #f1be68 0%, #da8a42 138%);
  /* linear-gradient(135deg, #2A1ECD 0%, #D038EB 138%); */
  background-clip: text;
  color: transparent;
}
.textBGGrey {
  background: linear-gradient(
    267.03deg,
    rgba(148, 152, 158, 0) -34.74%,
    rgba(111, 115, 122, 0.386987) 33.11%,
    rgba(66, 70, 78, 0.859375) 76.49%,
    #343841 103.32%
  );

  /* linear-gradient(135deg, #F1BE68 0%, #DA8A42 138%); */
  /* linear-gradient(135deg, #2A1ECD 0%, #D038EB 138%); */
  background-clip: text;
  color: transparent;
}

.elipse1 {
  position: absolute;
  width: 402px;
  height: 370px;
  margin-top: 55px;
  margin-left: 160px;
  background: linear-gradient(136.37deg, #2a1ecd 0%, #d038eb 138.56%);
  filter: blur(200px);
}

.elipse2 {
  position: absolute;
  width: 182px;
  height: 172px;
  margin-top: 70px;
  margin-left: 850px;
  background: linear-gradient(136.37deg, #2a1ecd 0%, #d038eb 138.56%);
  filter: blur(200px);
}

.useCaseImg {
  position: absolute;
  height: "458px";
  width: "674px";
  justify-content: center;
  align-items: center;

  /* margin-left: 5px; */
  /* padding-top: 100px; */
}
.useCaseImg2 {
  position: absolute;
  height: "186px";
  width: "273px";
  padding-top: "272px";
  /* padding-left: 750px; */
  /* left: 59%; */
  /* padding-top: 100px; */
}

.useCaseImg3 {
  position: absolute;
  /* padding-left: 750px; */
  /* left: 80%; */
  height: "186px";
  width: "273px";
  /* padding-top: 100px; */
}

.blackobj2 {
  position: absolute;
  width: 722px;
  height: 543px;
  /* padding-top: 100px; */
  /* padding-left: 100px; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -300px;
  margin-bottom: -290px;
  margin-left: 550px;
  /* right: 0.1%; */
  /* filter: blur(36px) drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25)); */
  /* transform: matrix(1, 0, 0, 1, 0, 0); */
  filter: blur(23px) drop-shadow(40px 54px 34px rgb(255, 255, 255));
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.lineSeperator {
  /* position: absolute; */
  width: 380px;
  height: 0px;
  /* left: 198px;
      top: 3528px; */

  border: 0.5px solid #797979;
}

.objectBox1 {
  position: absolute;
  width: 222px;
  height: 213px;
  /* left: 870px;
      top: 342px; */
  /* margin-top: 140px; */
  /* margin-left: 105px; */
  /* background: #1A1C1F; */
  opacity: 0.25;
  border-radius: 17px;
  z-index: 22;
}

.objectBox2 {
  position: absolute;
  width: 187px;
  height: 141px;
  /* left: 7%; */
  /* top: 374.35px; */
  margin-top: 10px;
  margin-left: -100px;
  z-index: 23;

  /* background: url(.png); */
  filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  /* transform: matrix(1, -0.08, -0.08, 1, 0, 0); */
}

.elipse3 {
  position: absolute;
  /* left: 100px; */
  /* top: 349px;  */
  width: 163px;
  height: 156px;
  background: #282c30;
  /* linear-gradient(136.37deg, #2A1ECD 0%, #D038EB 138.56%); */
  opacity: 0.5;
  filter: blur(43px);
}

.buttonShare {
  position: absolute;
  z-index: 30;
}

.youtube {
  color: "white";
  height: 61px;
  width: 61px;
  text-transform: none;
  margin-left: -10px;
  margin-top: 20px;
  background: linear-gradient(180deg, #e62117 0%, #bd1007 100%);
  box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
  /* border-radius: 50; */
  border-radius: 13.2167px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.GoogleDrive {
  position: absolute;
  color: "white";
  height: 61px;
  width: 61px;
  text-transform: none;
  margin-top: 75px;
  margin-left: 180px;
  background: linear-gradient(212.92deg, #f6c338 7.73%, #78b54e 35.27%, #0fa569 66.34%, #217cc5 93.88%);
  box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
  border-radius: 13.2167px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Dropbox {
  position: absolute;
  color: "white";
  height: 61px;
  width: 61px;
  text-transform: none;
  margin-top: 95px;
  margin-left: 130px;
  background: linear-gradient(180deg, #0d2481 0%, rgba(0, 27, 210, 0.94) 100%);
  box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
  border-radius: 13.2167px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.OneDrive {
  position: absolute;
  color: "white";
  height: 61px;
  width: 61px;
  margin-top: 160px;
  margin-left: 50px;
  text-transform: none;
  background: linear-gradient(180deg, #007afe 0%, rgba(0, 30, 236, 0.94) 100%);
  box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
  border-radius: 13.2167px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lap1 {
  position: absolute;
  padding-left: 10px;
  padding-top: 10px;
  z-index: 21;
}

.lap1Border {
  position: absolute;
  width: 234.2px;
  height: 165.26px;
  /* margin-top: 130px; */
  /* margin-left: 110px; */

  background: rgba(255, 255, 255, 0.25);
  border-radius: 10.9904px;
  z-index: 20;
}

.lap2 {
  position: absolute;
  padding-left: 2px;
  padding-top: 2px;
  z-index: 23;
}

.lap2Border {
  /* position: absolute; */
  width: 133.56px;
  height: 92.49px;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10.9904px;
  z-index: 22;
}

.vr {
  position: absolute;
  z-index: 25;
  margin-top: 120px;
  margin-left: -20px;
}

.purpleobj2 {
  position: absolute;
  /* width: 421.98px;
      height: 316.49px; */
  margin-top: 250px;
  margin-left: -12%;
  /* left: -0.5%; */
  justify-content: flex-start;
  align-items: flex-start;
  filter: blur(12px) drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  transform: rotate(2.93deg);
}

.createIcon {
  /* margin-left: 30px; */
  align-self: center;
  /* background: #ffffff;
  border-radius: 100px; */
  width: 223px;
  height: 206px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

.createIcon1 {
  position: absolute;
  align-self: center;
  background: #ffffff;
  border-radius: 80px;
  width: 80px;
  height: 80px;
  left: 55%;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  /* display: flex; */
}

.vectorBG {
  position: absolute;
  z-index: 19;
  margin-left: -17px;
  margin-top: -75px;
  left: 0%;
  right: 0.01%;
  background: linear-gradient(179.74deg, #f1f4f6 0.23%, rgba(255, 255, 255, 0.03) 85.25%, rgba(255, 255, 255, 0) 99.77%);
  /* width: 100%; */
  /* top: 0%;
  bottom: 0%; */
  /* filter: blur(1px); */
}
.vectorBGHome {
  opacity: 80%;
}

.vectorBGContainer {
  z-index: 20;
  margin-bottom: 300px;
}

.vectorBGIcons {
  width: 19.26px;
  height: 19px;
  border-radius: 50%;
  background: linear-gradient(136.37deg, #2a1ecd 0%, #d038eb 138.56%);
}
.slider-control-centerleft {
  display: none !important ;
}

.vectorBGIconsDisabled {
  width: 19.26px;
  height: 19px;
  border-radius: 50%;
  background: #464e54;
}
.carouselDim {
  height: 584px;
  width: 584px;
}
.machineImage {
  position: absolute;
  /* width: 584px;
  height: 584px;
  left: 210px;
  top: 4458px; */
  /* margin-top: ; */
  z-index: 312;
}

.machineElipse1 {
  position: absolute;
  width: 664px;
  height: 60px;
  /* left: 157px;
      top: 4767px; */
  margin-top: 300px;
  margin-left: -40px;
  border-radius: 50%;
  border-left: 2px solid #1a1a1a;
  border-right: 2px solid #1a1a1a;
  border-top: 2px solid #1a1a1a;
  /* border: 2px solid #A4A4A4; */
  /* box-sizing: border-box; */
  z-index: 311;
  /* transform: rotate(-360deg); */
  /* transform: matrix(-1, 0, 0, 1, 1, 1); */
}
.machineElipse2 {
  position: absolute;
  width: 664px;
  height: 60px;
  /* left: 157px;
      top: 4767px; */
  margin-top: 300px;
  margin-left: -40px;
  border-radius: 50%;
  border-left: 2px solid #1a1a1a;
  border-right: 2px solid #1a1a1a;
  border-bottom: 2px solid #1a1a1a;
  /* border: 2px solid #A4A4A4; */
  /* box-sizing: border-box; */
  z-index: 314;
}

.machineElipse3 {
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-top: 343px;
  margin-left: 430px;
  /* left: 643px;
      top: 4806px; */
  z-index: 315;
  /* background: #A4A4A4; */
}
.vectorBGElipse {
  z-index: 130;
  position: absolute;
  width: 312px;
  height: 290px;
  /* left: 335px;
      top: 4649px;  */
  margin-top: 170px;
  /* button */
  margin-left: 170px;
  background: rgba(255, 255, 255, 0.53);
  /* linear-gradient(136.37deg, #2A1ECD 0%, #D038EB 138.56%); */
  opacity: 0.93;
  filter: blur(100px);
}

.contact {
  /* position: absolute; */
  width: 1209px;
  /* height: 591px; */
  /* left: 116px;
      top: 5251px; */

  /* button */

  background: linear-gradient(136.37deg, #2a1ecd 0%, #d038eb 138.56%);
  border-radius: 10px;
}

.inputField {
  background: #ffffff;
  border-radius: 5px;
  border-width: 0px;
  color: #a4a4a4;
  border-color: #464e54;
  width: 354.86px;
}

.contactObject1 {
  /* p   osition: absolute; */
  width: 20.44px;
  height: 204.63px;
  /* left: 228.44px;
      top: 5621.63px; */
  margin-top: 180px;
  margin-left: 100px;
  /* background: linear-gradient(161.48deg, #2A1ECD 0.15%, rgba(42, 30, 205, 0) 94.66%); */
  border-radius: 20px;
  transform: rotate(-180deg);
}

.contactObject2 {
  /* position: absolute; */
  width: 17.84px;
  height: 160.58px;
  /* left: 320px;
      top: 5312.3px; */
  margin-top: 80px;
  margin-left: 100px;
  /* background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%); */
  border-radius: 20px;
}
.contactObject3 {
  /* position: absolute; */
  width: 17.84px;
  height: 160.58px;
  /* left: 320px;
      top: 5312.3px; */
  margin-top: 20px;
  margin-left: -17.84px;
  /* background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%); */
  border-radius: 20px;
}

.contactObject4 {
  /* position: absolute; */
  width: 17.84px;
  height: 340.12px;
  /* left: 390.84px;
      top: 5766.12px; */
  margin-top: 180px;
  margin-left: 40px;
  background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2a1ecd 100%);
  border-radius: 20px;
  transform: rotate(-180deg);
}
.blackobj3 {
  /* position: absolute; */
  width: 212.82px;
  height: 159.89px;
  /* left: 396.82px;
      top: 5713.11px; */
  margin-bottom: -100px;
  margin-left: 60px;
  margin-top: -30px;
  filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.contactObject5 {
  width: 17.84px;
  height: 340.12px;
  background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2a1ecd 100%);
  border-radius: 20px;
  margin-left: -50px;
}

.contactObject6 {
  width: 17.84px;
  height: 184px;
  background: linear-gradient(180deg, #d038eb 0%, rgba(208, 56, 235, 0) 100%);
  border-radius: 20px;
  margin-top: 40px;
  margin-left: -50px;
}

.contactObject7 {
  /* width: 20.07px;
      height: 113.75px; */
  /* background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%); */
  /* margin-top: 3px; */
  margin-left: -60px;
  border-radius: 20px;
  /* transform: rotate(90deg); */
  /* margin-left: 140px; */
}

.contactObject8 {
  width: 26.07px;
  height: 157.63px;
  /* background: linear-gradient(144.09deg, #D038EB -26.95%, rgba(208, 56, 235, 0) 100%); */
  background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #d038eb 100%);
  border-radius: 20px;
  transform: rotate(90deg);
  margin-left: -115px;
  margin-top: -34px;
}

.contactObject9 {
  width: 17.84px;
  height: 160.58px;
  background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2a1ecd 100%);
  border-radius: 20px;
  transform: rotate(90deg);
  margin-left: 165px;
  margin-top: -38px;
  opacity: 0.8;
  z-index: 42;
}
.contactObject10 {
  width: 17.84px;
  height: 104.75px;
  /* background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%); */
  border-radius: 20px;
  transform: rotate(90deg);
  /* margin-top: 38px; */
  margin-left: 50px;
  z-index: 40;
}
.contactObject11 {
  /* width: 20.07px;
      height: 113.75px; */
  /* background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%); */
  margin-top: -200px;
  margin-left: -60px;
  border-radius: 20px;
  /* transform: rotate(90deg); */
  /* margin-left: 140px; */
}
.blackobj4 {
  /* position: absolute; */
  /* width: 212.82px;
      height: 159.89px; */
  /* left: 396.82px;
      top: 5713.11px; */
  margin-bottom: -70px;
  margin-left: -100px;
  /* margin-top: 60px;   */
  filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.purpleobj3 {
  /* position: absolute; */
  /* width: 195.74px;
      height: 146.8px; */
  margin-left: 50px;
  margin-top: -30px;
  margin-bottom: -30px;
  filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
  /* transform: rotate(-13.93deg) */
}

.contactBg1 {
  /* background: #C4C4C4; */
  width: 500px;
  position: absolute;
  background: #2b99ff;
  mix-blend-mode: overlay;
  filter: blur(500px);
}
.company {
  /* background: #C4C4C4; */
  /* width: 1166.35px; */
  background: linear-gradient(135deg, #c4c4c4 0%, #c4c4c4 138%);
  background-clip: text;
  color: transparent;
  padding-top: 80px;
}

.companyBg1 {
  /* background: #C4C4C4; */
  position: absolute;
  width: 1265.38px;
  height: 719px;
  background: rgba(255, 255, 255, 0.52);
  mix-blend-mode: overlay;
  filter: blur(250px);
}

.companyBg2 {
  /* background: #C4C4C4; */
  position: absolute;
  width: 1265.38px;
  height: 565.38px;
  background: rgba(255, 255, 255, 0.53);
  mix-blend-mode: overlay;
  filter: blur(383.548px);
}

.companyBg3 {
  /* background: #C4C4C4; */
  position: absolute;
  width: 665.38px;
  height: 565.38px;
  background: #ffffff;
  mix-blend-mode: overlay;
  filter: blur(383.548px);
}

.companyImages {
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
}
