.inputField {
    background: #F4F4FC;
    border-radius: 5px;
    color: #A4A4A4;
    border-color: #464E54;
}
.body3 {
    line-height: 19px;
    background: linear-gradient(135deg, #F1BE68 0%, #DA8A42 138%);
    /* linear-gradient(135deg, #2A1ECD 0%, #D038EB 138%); */
    background-clip: text;
    color: transparent;
}