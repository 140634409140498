.lineSeperator{
    /* border-width: 10px; */
    /* width: 1200px; */
    /* border-image-source: linear-gradient(135deg, #2A1ECD 0%, #D038EB 100%); */
    /* color: linear-gradient(135deg, #2A1ECD 0%, #D038EB 138%); */
    border-top: 2px solid #282C30;
    margin-top: 40px;
}
.closeButton{
    border-radius: 50%;
    -webkit-filter: invert(1) contrast(10%) brightness(50%);
    filter: invert(1) contrast(10%) brightness(50%);
}
.closeButton:hover{
    -webkit-filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
    filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
}