
.componentMargin {
    margin-top: 40px;
     
 }
 .body3 {
     line-height: 19px;
     background: linear-gradient(135deg, #F1BE68 0%, #DA8A42 138%);
     /* linear-gradient(135deg, #2A1ECD 0%, #D038EB 138%); */
     background-clip: text;
     color: transparent;
 }
 
 .inputField {
     background: #F4F4FC;
     border-radius: 5px;
     color: #A4A4A4;
     border-color: #464E54;
 }
 
 .object1 {
     /* position: absolute; */
 width: 20px;
 height: 144px;
 /* left: 811px;
 top: 176px; */
 margin-left: 70px;
 background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%);
     opacity: 0.42;
     border-radius: 20px;
 }
 
 .object2 {
     /* position: absolute; */
 width: 19px;
 height: 107px;
 /* left: 1096px;
 top: 275px; */
 margin-top: 70px;
 
 background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2A1ECD 100%);
 border-radius: 20px;
     opacity: 0.42;
     transform: rotate(90deg);
 }
 
 .object3 {
     /* position: absolute; */
 width: 20px;
 height: 276px;
 /* left: 1132px;
 top: 193px; */
 
 background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2A1ECD 100%);
     opacity: 0.42;
     border-radius: 20px;
 }
 
 .object4 {
     position: absolute;
 width: 22.12px;
 height: 121.16px;
 /* left: 998.42px;
 top: 447.78px; */
 margin-top: 260px;
 margin-left: 260px;
 background: linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%);
     opacity: 0.42;
     border-radius: 20px;
 }
 
 .object5 {
     position: absolute;
     width: 22px;
     height: 217.98px;
     /* left: 798.72px;
     top: 677.99px; */
     margin-top: 270px;
     margin-left: 60px;
     
     background: linear-gradient(161.48deg, #2A1ECD 0.15%, rgba(42, 30, 205, 0) 94.66%);
     border-radius: 20px;
     opacity: 0.42;
     transform: rotate(-180deg);
 }
 
 .object6 {
     position: absolute;
     width: 22px;
     height: 196px;
     /* left: 860.88px;
     top: 599px; */
     margin-top: 400px;
     margin-left: 160px;
     background: linear-gradient(180deg, #D038EB 0%, rgba(208, 56, 235, 0) 100%);
     border-radius: 20px;    
     opacity: 0.42;
 }
 
 .object7 {
     position: absolute;
     width: 19.01px;
     height: 176.96px;
     /* left: 1230px;
     top: 695.22px; */
     margin-top: 405px;
     margin-left: 320px;
     background: linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2A1ECD 100%);
     border-radius: 20px;
     transform: rotate(90deg);
     opacity: 0.42;
 }
 .object8 {
     /* position: absolute; */
     width: 57px;
     height: 9px;
     /* left: 930px;
     top: 722px; */
     /* margin-top: 240px;
     margin-left: 30px; */
     background: #D038EB;
     border-radius: 16px;
     cursor: pointer;
 }
 .object9 {
     /* position: absolute; */
     width: 9px;
     height: 9px;
     /* left: 1010px;
     top: 722px; */
     background: rgba(208, 56, 235, 0.48);
     border-radius: 16px;
     cursor: pointer;
 }
 
 .objectBox1 {
     position: absolute;
 width: 222px;
 height: 213px;
 /* left: 870px;
 top: 342px; */
 margin-top: 140px;
 margin-left: 105px;
 /* background: #1A1C1F; */
 border-radius: 17px;
 }
 .objectBox2 {
     position: absolute;
 width: 187px;
 height: 141px;
 /* left: 986.14px;
 top: 374.35px; */
 margin-top: 170px;
 margin-left: 50px;
 
 /* background: url(.png); */
 filter: drop-shadow(40px 54px 34px rgba(0, 0, 0, 0.25));
 /* transform: matrix(1, -0.08, -0.08, 1, 0, 0); */
 }
 
 .objectBox2Border {
     position: absolute;
     width: 155px;
     height: 156px;
     /* left: 816.26px;
     top: 353.22px; */
     margin-top: 160px;
     margin-left: 55px;
     border: 2px solid #D038EB;
     box-sizing: border-box;
     transform: rotate(4.41deg);
 }
 
 .elipse {
     position: absolute;
 /* left: 815px;
 top: 349px; */
 width: 223px;
 height: 206px;
 margin-top: 150px;
 margin-left: 50px;
 
 /* button */
 
 /* background: linear-gradient(136.37deg, #2A1ECD 0%, #D038EB 138.56%); */
 opacity: 0.32;
 filter: blur(43px);
 }
 
 .buttonShare {
     position: absolute;
     margin-top: 135px;
     margin-left: 215px;
 
 }
 
 .buttonYoutube {
     position: absolute;
     margin-top: 210px;
     margin-left: 100px;
 
 }
 .youtube {
     color: "white";
     height: 61px;
     width: 61px;
     margin-top: 3;
     text-transform: none;
     background:
     linear-gradient(180deg, #E62117 0%, #BD1007 100%);
     box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
     /* border-radius: 50; */
     border-radius: 13.2167px;
     justify-content: "space-around";
     flex-direction: column;
 }
 
 .GoogleDrive {
     color: "white";
     height: 61px;
     width: 61px;
     margin-top: 3;
     text-transform: none;
     background: linear-gradient(212.92deg, #F6C338 7.73%, #78B54E 35.27%, #0FA569 66.34%, #217CC5 93.88%);
     box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
     border-radius: 13.2167px;
     justify-content: "space-around";
     flex-direction: column;
 }
 .buttonGoogleDrive {
     position: absolute;
     margin-top: 230px;
     margin-left: 285px;
 }
 
 .Dropbox {
     color: "white";
     height: 61px;
     width: 61px;
     margin-top: 3;
     text-transform: none;
     background: linear-gradient(180deg, #0D2481 0%, rgba(0, 27, 210, 0.94) 100%);
     box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
     border-radius: 13.2167px;
     justify-content: "space-around";
     flex-direction: column;
 }
 .buttonDropbox {
     position: absolute;
     margin-top: 255px;
     margin-left: 230px;
 }
 
 .OneDrive {
     color: "white";
     height: 61px;
     width: 61px;
     margin-top: 3;
     text-transform: none;
     background: linear-gradient(180deg, #007AFE 0%, rgba(0, 30, 236, 0.94) 100%);
     box-shadow: 0px 8.13333px 10.1667px rgba(0, 0, 0, 0.25);
     border-radius: 13.2167px;
     justify-content: "space-around";
     flex-direction: column;
 }
 .buttonOneDrive {
     position: absolute;
     margin-top: 320px;
     margin-left: 150px;
 }
 
 
 .lap1 {
     position: absolute;
     margin-top: 140px;
     margin-left: 120px;
 }
 
 .lap1Border{
     position: absolute;
     width: 234.2px;
     height: 165.26px;
     margin-top: 130px;
     margin-left: 110px;
     background: rgba(255, 255, 255, 0.25);
     border-radius: 10.9904px;
 }
 
 .lap2{
     position: absolute;
     margin-top: 240px;
     margin-left: 220px;
 }
 
 .lap2Border{
     position: absolute;
     width: 133.56px;
     height: 92.49px;
     margin-top: 240px;
     margin-left: 220px;
     background: rgba(255, 255, 255, 0.25);
     border-radius: 10.9904px;
 }
 
 .vr{
     position: absolute;
     margin-top: 245px;
     margin-left: 88px;
 }