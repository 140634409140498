.container {
    background-color: #0F1213;
    /* background: #0F1213; */
}

.inputField {
    background: #f4f4fc;
    border-radius: 5px;
    color: #a4a4a4;
    border-color: #464e54;
    /* min-width: 200px;
    width: 300px; */
    overflow:hidden;
    /* position: absolute; */
}

.inputField2 {
    background: #f4f4fc;
    border-radius: 5px;
    color: #a4a4a4;
    border-color: #464e54;
    /* min-width: 200px;
    width: 620px; */
}

.milSignupElipse2 {
    position: absolute;
    top: '20%';
    left: '40%';
    filter: blur(20px) drop-shadow(40px 54px 34px rgb(255, 255, 255));
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.textBgStyle {
    line-height: 19px;
    background: linear-gradient(135deg, #f1be68 0%, #da8a42 138%);
    /* linear-gradient(135deg, #2A1ECD 0%, #D038EB 138%); */
    background-clip: text;
    color: transparent;
    cursor: "pointer";
    font-size: 15px;
    padding-left: 5px;
}

.closeButton{
    border-radius: 50%;
    -webkit-filter: invert(1) contrast(10%) brightness(50%);
    filter: invert(1) contrast(10%) brightness(50%);
}
.closeButton:hover{
    -webkit-filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
    filter: invert(58%) sepia(157%) saturate(298%) hue-rotate(359deg) brightness(130%) contrast(75%);
}
